<header class="Infy_Header">
    <div id="headerComp-header-DIV" class="containerPos">
        
        <a class="customMenuAnchorSize" (click)="home()">
            <img class="customLogoSize responsive-logo" alt="logo" src="assets/images/vendor_images/Group 17520@2x.png"/>
        </a>

       

        <div class="lineStyle"></div>

        <span class="text-style">Partner Hub</span>

        <div class="selectStyle" *ngIf="!unAuthFlag">
            <mat-select class="selectPadFont" panelClass="customPanel" [(value)]="companyHiringTypeId"
                (selectionChange)="getHeaderEntities(companyHiringTypeId)">
                <mat-option *ngFor="let entity of entities" [value]="entity.id">
                    {{ entity.organization }}
                </mat-option>
            </mat-select>
        </div>
        
        <ul id="headerComp-header-div2" class="float-right">

            <li>
                <span>
                    <a *ngIf="!condition" [matMenuTriggerFor]="menu2" class="custom-button menuTriggerAnchorStyle"
                        href="javascript:void(0)">
                        <span>{{ userDetailsData?.vendorName | initials }}</span>
                    </a>
                    <mat-menu #menu2="matMenu">
                        <button class="marB8" mat-menu-item>
                            <span class="userNameClass">Hello, {{userDetailsData?.vendorName}} !</span>
                        </button>
                        <div *ngIf="!unAuthFlag">
                            <mat-form-field (click)="$event.stopPropagation()" style="margin-left: 10px; margin-right: 10px"
                            appearance="outline" floatLabel="always">
                            <mat-label>Switch role</mat-label>
                            <mat-select class="limitCss" [(value)]="selectedRole" (selectionChange)="closeMenu()">
                                <mat-option *ngFor="let role of roles" [value]="role.role">
                                    {{ role.displayName
                                    }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        </div>
                        
                        <button mat-menu-item *ngIf="(!unAuthFlag && !infyUser ) || !infyUser">
                            <a class="menuButtonDecor" href="javascript:void(0)"
                                (click)="userProfileDetails()"><span>Your Profile</span>
                            </a>
                        </button>
                        <button *ngIf="(!unAuthFlag && !infyUser ) || !infyUser" class="menuButtonDecor" mat-menu-item (click)="changePassword()">
                            Change Password
                        </button>
                        <button class="menuButtonDecor" mat-menu-item (click)="logout()">
                            Logout
                        </button>
                    </mat-menu>
                </span>
            </li>
        </ul>
    </div>
</header>
<div *ngIf="!hideTabs" class="d-flex justify-content-between tabDivStyle">
        <div class="headerTab">
            <div class="d-flex">
                <div class="DSA_wb_customTab DSA_navTabWhite DSA_wb_Secondary marL32 widthLateralTabs marT4 nopadL">
                <mat-tab-group *ngIf="!showMaintenance"  disableRipple #tabGroup (selectedTabChange)="tabChanged($event.index)" [(selectedIndex)]="selectedTabIndex">
                    <mat-tab  *ngFor="let tab of tablists" #tab>
                        <ng-template mat-tab-label>
                            <span class="tab-label ">{{ tab.feature }}</span>
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>
            </div>
            </div>
    </div>
    
    <button  mat-icon-button class="burgerbtn" [matMenuTriggerFor]="menu" >
        <mat-icon class="burger">{{isMenuOpen ? "close" : "menu"}}</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <button *ngIf="helpLinks.length > 0"  mat-menu-item class="menuBtn" [matMenuTriggerFor]="menuHP">Helplinks</button>
        <mat-menu #menuHP="matMenu">
                <div *ngFor="let link of helpLinks"><a class="text-underline"  mat-menu-item class="menuOptn" matTooltip="{{link?.description}}" matTooltipClass="DSA_tooltip" matTooltipPosition="above" (click)="downloadFile(link?.url, link?.name)">{{link?.name}} </a>
                </div>
            </mat-menu>
        <a *ngFor="let link of links" mat-menu-item href="{{link.value}}" target="_blank" class="anchorDesign marT8">{{link.label}}</a>
    </mat-menu>

    <div class="menuDesk">
        <button *ngIf="helpLinks.length > 0" class="menuBtn" mat-button [matMenuTriggerFor]="menuDT">
            <mat-icon>arrow_drop_down</mat-icon>Helplinks</button>
        <mat-menu #menuDT="matMenu">
            <div *ngIf="helpLinks">
                <div *ngFor="let link of helpLinks">
                    <ng-container *ngIf="link?.url.includes('infyme.infosysapps.com')">
                        <a class="text-underline" mat-menu-item class="anchorDesign" target="_blank"
                        matTooltip="{{link?.description}}" matTooltipClass="DSA_tooltip" matTooltipPosition="above"
                        href="{{link?.url}}">{{link?.name}}
                        </a>
                    </ng-container>
                    <ng-container *ngIf="!link?.url.includes('infyme.infosysapps.com')">
                        <a class="text-underline" mat-menu-item class="menuOptn"
                        matTooltip="{{link?.description}}" matTooltipClass="DSA_tooltip" matTooltipPosition="above"
                        (click)="downloadFile(link?.url, link?.name)">{{link?.name}} 
                        </a>
                    </ng-container>
                </div>
            </div>
        </mat-menu> 
        <span *ngFor="let link of links" class="marT8 flexWidth">
            <a href="{{link.value}}" target="_blank" class="anchorDesign">{{link.label}}</a>
        </span>  
    </div>
</div>
<ng-container *ngIf="showMaintenance">
    <app-maintenance></app-maintenance>
</ng-container>