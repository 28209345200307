import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, Output, SimpleChanges, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, NavigationExtras, ResolveEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import { VendorService } from 'src/app/core/services/vendor.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { SharedService } from 'src/app/core/services/shared.service';
import { EventEmitter } from '@angular/core';
// import { PaymentComponent } from 'src/app/modules/vendor/components/payment/payment.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PaymentComponent } from 'src/app/modules/vendor/components/payment/payment.component';
import { TelemetryService,INTERACTEVENTS, IMPRESSIONEVENTS } from '@infosys-it/angular-telemetry-sdk';
import { HeaderService } from './header.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  @Input() entities: any[] = [];
  @Input() roles: any[] = [];
  @Input() entityRoles: any[];
  @Input() roleAndFeatures: any[];
  @Input() links: any[];
  @Input() userDetailsData: any;
  @Output() homeEvent: EventEmitter<any> = new EventEmitter();
  @Output() maintenanceMode: EventEmitter<any> = new EventEmitter();
  companyHiringTypeId: any;
  selectedRole: any;
  selectedTabIndex: number = 0;
  condition: boolean = false;
  username1 = "Sai Prasann Goud";
  username2 = "Nitish Pavithran";
  username3 = "Snehal Shinde"
  loginUsername: string = this.username1;
  tablists: any[] = [];
  floatLabelControl = new UntypedFormControl('auto')
  @ViewChild(MatMenuTrigger) menu2: MatMenuTrigger;
  SelectedEntityRoles: any[] = [];
  selectedRoleFeatures: any;
  keyClockUrl: '';
  vendorCode: any = localStorage.getItem("vendorCode");
  hideTabs = false;
  menuOptions: any = [{ "name": "PartnerHub Flow", "url": "", "description": "click here to check partnerhub flow" }, { "name": "Invoice Flow", "url": "", "description": "click here to check invoice flow" }, { "name": "CreditNote Flow", "url": "", "description": "click here to check credit note flow" }, { "name": "Holiday Calender", "url": "", "description": "click here to see holiday calender" },]
  unAuthFlag: boolean=true;
  userEmail:string="";
  infyUser:boolean=false;
  helpLinks=[];
  showMaintenance: Boolean = false;

  constructor(private _formBuilder: UntypedFormBuilder, private router: Router, private vendor: VendorService, private sharedService: SharedService, private activatedRoute: ActivatedRoute, private cdr: ChangeDetectorRef,
    private telemetryService:TelemetryService,
    public dialog: MatDialog ,private headerService:HeaderService) { }

  ngOnInit(): void {
    this.telemetryService.impression({type: IMPRESSIONEVENTS.VIEW, itype:"Component", pageid: 'HeaderComponent', detail:'OnInit Load'});

    this.userEmail=localStorage.getItem("email");
    this.userEmail.includes('infosys.com')? this.infyUser=true: this.infyUser=false; 
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.hideTabs = event.url.includes('/14') ? true : false;
        this.cdr.detectChanges();
      }
    })
    // based on selected entity or role select, router is called to call specific section json module. Ex: If it is job cards or invoice respective json is called. 

    this.companyHiringTypeId = this.entities[0]?.id;
    localStorage.setItem("companyHiringTypeId", this.companyHiringTypeId);
    this.refreshJobCards();
    this.sharedService.access$.subscribe((val) => {
      this.unAuthFlag=val;
    })
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.telemetryService.impression({type: IMPRESSIONEVENTS.VIEW, itype: "Component", pageid: 'HeaderComponent', detail:'OnChange Load'});
    console.log(this.entityRoles, "entity", this.entities, this.userDetailsData);
    if (this.entities.length > 0) {
      
      this.companyHiringTypeId = this.entities[0].id;
      localStorage.setItem("companyHiringTypeId", this.companyHiringTypeId);
    }
    if (this.entityRoles) {
      this.SelectedEntityRoles = this.entityRoles.filter(data => {
        if (data.entityId == this.companyHiringTypeId) {
          return data.roles;
        }
      });
    }

    this.roles = this.SelectedEntityRoles[0]?.roles;
    if (this.roles) {
      this.selectedRole = this.roles[0]?.role;
      localStorage.setItem("selectedRole", this.selectedRole);
    }
    if(this.getMaintenanceMode()){
    if (this.selectedRole) {
      this.selectedRoleFeatures = this.roleAndFeatures.filter(data => {
        if (data.role == this.selectedRole) {
          return data['features'];
        }
      })
      // console.log(this.selectedRole,"selected features",this.selectedRoleFeatures);
    }
    if (this.selectedRoleFeatures) {
      this.tablists = [];
      this.helpLinks=[];
      // this.helpLinks=this.selectedRoleFeatures[0]?.helpLinks;
      this.selectedRoleFeatures[0]?.helpLinks?.forEach(links => {
        this.helpLinks.push(links);
      })
      console.log(this.helpLinks,"helpLinks",this.selectedRoleFeatures);
      this.selectedRoleFeatures[0]?.features.forEach(featureObj => {
        this.tablists.push(featureObj);
        this.tabChanged(0);
        this.selectedTabIndex = 0
      })
          }
    }
  }

  closeMenu() {
    console.log(this.menu2);
    this.menu2.closeMenu();
    this.getHeaderRolesTabs(this.companyHiringTypeId, this.selectedRole)
  }

  //rolesFeatures
  getHeaderRolesTabs(selectedEntity, selectedRole) {
    localStorage.setItem("selectedRole", selectedRole);
    if(this.getMaintenanceMode()){
    this.vendor.getHeaderRolesTabs(selectedEntity, selectedRole).subscribe((data) => {
      console.log(data, "vall", selectedRole)
      this.tablists = [];
      if (data) {
        this.tablists = data?.features;
        this.helpLinks = data?.helpLinks;
      }
    },error=>{
      // console.log(error, "error")
          let info = {
            msg: "Technical issue occured, please try again later",
            isSuccess: false
          }
          this.sharedService.openSnackBar(info,'success-snack');
    });}
  }

  //on entity change
  getHeaderEntities(companyHiringTypeId) {
    try{
      this.sharedService.loaderStart(false);
    this.telemetryService.interact({type: INTERACTEVENTS.CLICK, itype: "Method", pageid: 'HeaderComponent', detail: 'Entity change clicked',  companyhiringtypeid: companyHiringTypeId});
    this.tablists = [];
    localStorage.setItem("companyHiringTypeId", companyHiringTypeId);
    this.companyHiringTypeId = Number(localStorage.getItem("companyHiringTypeId"));
    this.vendor.getHeaderEntityTabs(this.companyHiringTypeId).subscribe((val) => {
      // console.log(val,"vall")
      if (val) {
        this.roles = val?.entityRoles?.roles;
        this.helpLinks=val?.entityRoleFeatures[0]?.helpLinks?val?.entityRoleFeatures[0]?.helpLinks:[];
        console.log(this.helpLinks, "helplinks entitychange");
        this.selectedRole=this.roles[0]?.role;
        localStorage.setItem("selectedRole", this.selectedRole);
        // console.log(this.selectedRole, "selectedRole", val);
        if(this.getMaintenanceMode()){
        this.selectedRoleFeatures = val?.entityRoleFeatures?.filter(data =>
          data.role == this.selectedRole
        );
        this.selectedRoleFeatures[0]?.features.forEach(featureObj => {
          this.tablists.push(featureObj);
        this.tabChanged(0);
        this.selectedTabIndex = 0;
        this.refreshJobCards();  
        })}
      }  
    }, error => {
      let info = {
        msg: "Technical issue occured, please try again later",
        isSuccess: false
      };
      this.sharedService.openSnackBar(info,'success-snack');
      this.sharedService.loaderStart(true);
    });
  }
  catch(error){
    this.sharedService.loaderStart(true);
  }

  }

  tabChanged(tabSelectedIndex){
    let moduleId= this.tablists[tabSelectedIndex]?.moduleId;
    let feature = this.tablists[tabSelectedIndex]?.feature;
    if(feature && feature.includes('Payment')){
      this.openDialog()
      return
    }
    this.telemetryService.interact({type: INTERACTEVENTS.CLICK, itype: "Method", pageid: 'HeaderComponent',  detail: feature+ ' feature loaded' });
    console.log("tabsel", tabSelectedIndex, moduleId, this.tablists);
    if (!feature?.includes('Subcon Timesheet')) {
      this.router.navigateByUrl(`app-wall/app-generichome/generictemplate/${moduleId}`);
    }
    else {
      if(this.selectedRole=="ROLE_SUBCON"){
        //code for subcon timesheet privacy page url
        
        this.sharedService.dpoCheckForSubcon(this.selectedRole).subscribe(data => {
          console.log(data, "dpo check");
          if(data?.message=="SUBCON DPO consent needed"){
            let moduleId=36;
            this.router.navigateByUrl(`app-wall/app-generichome/generictemplate/${moduleId}`);
          
          }
          else{
            this.router.navigateByUrl(`app-wall/app-services/viewallservices/subview/subconTimesheet`);
          }
        }, error=> {
          console.log(error, "error")
          let info = {
            msg: "Technical issue occured, please try again later",
            isSuccess: false
          }
          this.sharedService.openSnackBar(info,'success-snack');
        })
        // this.router.navigateByUrl(`app-wall/app-generichome/generictemplate/${moduleId}`);
      }
      // this.router.navigateByUrl(`app-wall/app-services/viewallservices/subview/subconTimesheet`);
    }
  }

  openDialog(){
    const dialogRef: MatDialogRef<PaymentComponent> = this.dialog.open(PaymentComponent);
    dialogRef.afterClosed().subscribe(result => {
      // Logic to handle the dialog close event
      console.log('Dialog closed with result:', result,this.selectedTabIndex);
      // Add any additional actions here
      this.tabChanged(0);
      this.selectedTabIndex = 0;
    });
  }

  userProfileDetails() {
    this.telemetryService.interact({type: INTERACTEVENTS.CLICK, itype: "Method", pageid: 'HeaderComponent', detail: 'vendor profile details clicked'});
    this.router.navigate(['app-wall/app-generichome/generictemplate/' + environment.vendorProfileId]);
  }

  logout() {
    this.telemetryService.interact({type: INTERACTEVENTS.CLICK, itype: "Method", pageid: 'HeaderComponent', detail: localStorage.getItem('email')+' has logged out successfully having vendor code '+localStorage.getItem('vendorCode')});
    this.sharedService.logoutSession();
    localStorage.removeItem("companyHiringTypeId");
    // let url = encodeURI(location.origin);
    localStorage.removeItem('selectedRole');
    localStorage.removeItem('email');
    localStorage.removeItem('lastName');
    localStorage.removeItem('firstName');
    localStorage.removeItem('vendorCode');
    this.router.navigate(['']);
  }

  changePassword() {
    this.telemetryService.interact({type: INTERACTEVENTS.CLICK, itype: "Method", pageid: 'HeaderComponent', detail: 'change password activity started'});
    this.router.navigate(['change-password'])
  }

  home() {
    this.router.navigate(['/app-wall']);
    this.homeEvent.emit("home");
  }

  refreshJobCards() {
    this.sharedService.triggerRefresh();
  }
  
  downloadFile(url, name) {
    if(name == 'Holiday Calender'){
      const a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.click();
    }
    else {
  
      this.telemetryService.interact({type: INTERACTEVENTS.CLICK, itype: "Downloading", pageid: 'HeaderComponent', detail: 'download file clicked', name: name});
      this.vendor.getDownloadFile(url)
      .subscribe((blob) => {
        const name= url.split('=').pop();
        console.log(blob, 'blob',name ,"name of doc")
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        console.log('blob', blob);
        a.href = objectUrl;
        a.download = name;
        a.click();
      }, error=> {
        console.log(error, "error")
        let info = {
          msg: "Technical issue occured, please try again later",
          isSuccess: false
        }
        this.sharedService.openSnackBar(info,'success-snack');
      });
    }
  }
  getMaintenanceMode():Boolean {
    let role = localStorage.getItem('selectedRole');
    let companyhiringtype = localStorage.getItem('companyHiringTypeId');
    console.log(role, companyhiringtype, "role and company hiring type");
    // this.sharedService.blobURL("maintenace-partnerHub.json").subscribe({
      this.headerService.getMaintenanceJson('./assets/json/maintenace-partnerHub.json').subscribe({
      next: async(maintenanceConf) => {
      console.log(maintenanceConf, "mainte json --updated");
      let list = maintenanceConf.find(maintenanceObj => (maintenanceObj['roleCode']?.toLowerCase() === role?.toLowerCase() && maintenanceObj['companyHiringTypeId'] === companyhiringtype));
      console.log(list, "mainte json --updated");

      if (list && list.maintenanceMode) {
        this.showMaintenance = true
        this.maintenanceMode.emit(true);
        return false
      }
      else {
        
        this.showMaintenance = false
        this.maintenanceMode.emit(false);
        return true
      }
    },
   error: (error)=>{
      console.log(error, "error")
      this.showMaintenance = true
      this.maintenanceMode.emit(true);
      return false
    },
    complete: () => {
      console.log('mainte json --updated completed');
    }
   });
    return true
  }
  
}
